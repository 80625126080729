<template>
  <div class="mt-14 font-proximaLight text-sm">
    <div class="flex">
      <div class="w-6/12 px-8 border-r border-primary">
        <div class="text-left">
          <span
            >1. Does your pet show any reluctance to getting into a
            carrier?</span
          >
          <div class="ml-4 mt-4 flex items-center">
            <!-- <InputRadioEach :title="$t('global_yes')" :id="1" :checked="this.petData.doc.type==1" @inputVal="inputVal" /> -->

            <InputRadioEach
              class="mr-10"
              :title="$t('global_yes')"
              :id="1"
              :checked="q1.choice == 1"
              @inputVal="reluctanceCarrier"
            />
            <InputRadioEach
              :title="$t('global_no')"
              :id="2"
              :checked="q1.choice == 2"
              @inputVal="reluctanceCarrier"
            />
          </div>
        </div>
        <div class="mt-6 text-left flex flex-col">
          <span
            >2. How does your pet travel to the (in a car, public transport
            etc.)?</span
          >
          <input
            type="text"
            placeholder="By car in a crate etc"
            v-model="travel"
            class="mt-2 w-full m-auto p-4 rounded-md"
          />
        </div>
        <div class="mt-6 text-left">
          <span
            >3. When it comes to humans, does your pet have a preference?</span
          >
          <div class="ml-4 mt-4 flex items-center">
            <InputRadioEach
              :title="$t('global_male')"
              class="mr-10"
              :id="5"
              :checked="q3.choice == 5"
              @inputVal="genderPreference"
            />
            <InputRadioEach
              :title="$t('global_female')"
              class="mr-10"
              :id="4"
              :checked="q3.choice == 4"
              @inputVal="genderPreference"
            />

            <InputRadioEach
              title="Doesn't matter"
              :id="6"
              :checked="q3.choice == 6"
              inputName="preference"
              @inputVal="genderPreference"
            />
          </div>
        </div>
        <FormError :errors="errors.q4" />
        <div class="mt-6 text-left">
          <span
            >4. Has your pet shown any dislike or avoidance of the following
            situations?</span
          >
          <div class="mt-4 text-left text-subheaderText flex flex-wrap">
            <label class="checkbox-container w-6/12 mb-4"
              >Entering an unknown indoor space (e.g. veterinary clinic, salon).
              <input
                type="checkbox"
                value="7"
                name="avoid1"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Animals or humans passing by reception.
              <input
                type="checkbox"
                value="8"
                name="avoid2"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Hearing the doorbell, intercom or phones ringing
              <input
                type="checkbox"
                value="9"
                name="avoid3"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Other people or animals in the waiting area.
              <input
                type="checkbox"
                value="10"
                name="avoid4"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Being approached by staff
              <input
                type="checkbox"
                value="11"
                name="avoid5"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Unfamiliar surfaces (e.g. weighing scale, grooming table).
              <input
                type="checkbox"
                value="12"
                name="avoid6"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Unfamiliar sounds in a hospital or similar settings.
              <input
                type="checkbox"
                value="13"
                name="avoid7"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Going into the exam room.
              <input
                type="checkbox"
                value="14"
                name="avoid8"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Being picked up or held.
              <input
                type="checkbox"
                value="15"
                name="avoid9"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Loud voices or loud noises (e.g. blow dryer).
              <input
                type="checkbox"
                value="16"
                name="avoid10"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Handling by strangers (e.g. having its temperature taken).
              <input
                type="checkbox"
                value="17"
                name="avoid11"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Use of unfamiliar equipment such as a stethoscope or an otoscope.
              <input
                type="checkbox"
                value="18"
                name="avoid12"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Being separated from you.
              <input
                type="checkbox"
                value="19"
                name="avoid13"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Direct eye contact with staff.
              <input
                type="checkbox"
                value="20"
                name="avoid14"
                v-model="inputValueAvoid"
                @change="toggleAvoidCheckboxesToArray"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="mt-6 text-left flex flex-col">
          <span
            >5. Does your pet have any sensitive areas that they don’t like
            touched by others?</span
          >
          <input
            type="text"
            placeholder="Inside the ears, tail etc."
            v-model="sensitiveareas"
            class="mt-2 w-full m-auto p-4 rounded-md"
          />
        </div>
        <div class="mt-6 text-left flex flex-col">
          <span
            >6. Are there any procedures that were difficult for your pet in the
            past (e.g. nail trimming, blood drawing, being bathed)? How did the
            pet react?</span
          >
          <input
            type="text"
            placeholder="nail trimming, reacted with aggression"
            v-model="procedures"
            class="mt-2 w-full m-auto p-4 rounded-md"
          />
        </div>
      </div>
      <div class="w-6/12 px-8">
        <img
          class="h-72 m-auto"
          src="@/assets/images/perpetual-platform.svg"
          alt="perpetual-platform"
        />
        <FormError :errors="errors.q7" />
        <div class="mt-6 text-left">
          <span
            >7. During travel, does your pet show any of the following
            behaviours?</span
          >
          <div class="mt-4 text-left text-subheaderText flex flex-wrap">
            <label class="checkbox-container w-6/12 mb-4"
              >Eyes wide open
              <input
                type="checkbox"
                value="23"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours1"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Pupils dilating
              <input
                type="checkbox"
                value="24"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours2"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Freezing in place
              <input
                type="checkbox"
                value="25"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours3"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Crouching or trembling
              <input
                type="checkbox"
                value="26"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours4"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Trying to escape
              <input
                type="checkbox"
                value="27"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours5"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Pacing
              <input
                type="checkbox"
                value="28"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours6"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Self-mutilating
              <input
                type="checkbox"
                value="29"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours7"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Fur / Feather loss
              <input
                type="checkbox"
                value="30"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours8"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Vocalizing
              <input
                type="checkbox"
                value="31"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours9"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Hiding
              <input
                type="checkbox"
                value="32"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours10"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Drooling
              <input
                type="checkbox"
                value="33"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours11"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Vomiting
              <input
                type="checkbox"
                value="34"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours12"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Urinating / Defecating
              <input
                type="checkbox"
                value="35"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours13"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 mb-4"
              >Panting
              <input
                type="checkbox"
                value="36"
                @change="toggleBehaviourCheckboxesToArray"
                v-model="inputValueBehaviour"
                name="behaviours14"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="mt-6 text-left flex flex-col">
          <span>8. Is there anything else important to know?</span>
          <input
            type="text"
            placeholder="After an initial phase of barking, if you give him treats he calms down. "
            v-model="importantelse"
            class="mt-2 w-full m-auto p-4 rounded-md"
          />
        </div>
      </div>
    </div>
    <SubmitButton
      v-if="q1.choice == 0 || q3.choice == 0"
      title="Submit Questionnaire"
      width="w-max"
      class="m-auto mt-10 cursor-not-allowed"
      background="bg-lightGrey"
    />
    <SubmitButton
      v-else
      background="bg-primary"
      title="Submit Questionnaire"
      width="w-max"
      class="m-auto mt-10"
      @click.native="submitQuestionnaire"
    />
  </div>
</template>

<script>
import InputRadioEach from "../forms/InputRadioEach.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import FormError from "../warning/FormError.vue";
import { intakeQuestionnaire } from "../../services/medicalHistoryService";

export default {
  components: {
    InputRadioEach,
    SubmitButton,
    FormError,
  },
  data() {
    return {
      petData: {},
      reluctance: "",
      travel: "",
      preference: "",
      sensitiveareas: "",
      procedures: "",
      importantelse: "",
      inputValueAvoid: [],
      avoidArrayValue: [],
      inputValueBehaviour: [],
      behaviourArrayValue: [],
      q1: { id: 1, custom_answer: null, questionnaire: 1, choice: 0 },
      q2: {},
      q3: { id: 3, custom_answer: null, questionnaire: 3, choice: 0 },
      errors: {},
    };
  },

  created() {
    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");

    this.petData = JSON.parse(retrievedObject);
  },

  methods: {
    reluctanceCarrier(i) {
      const result = i == this.$t("global_yes") ? 1 : 2;
      this.q1 = {
        id: 1,
        custom_answer: null,
        questionnaire: 1,
        choice: result,
      };
      console.log(this.q1);
    },
    genderPreference(i, e) {
      const result =
        i == this.$t("global_male") ? 5 : i == this.$t("global_female") ? 4 : 6;
      this.q3 = {
        id: 3,
        custom_answer: null,
        questionnaire: 3,
        choice: result,
      };
      console.log(this.q3);
      this.preference = e;
    },
    toggleAvoidCheckboxesToArray() {
      this.avoidArrayValue = this.inputValueAvoid;
    },

    toggleBehaviourCheckboxesToArray() {
      this.behaviourArrayValue = this.inputValueBehaviour;
    },

    validate() {
      const errors = {};

      console.log("q7: ", this.behaviourArrayValue);

      /* if (this.avoidArrayValue.length == 0)
        errors.q4 = "Please choose at least one response.";
      if (this.behaviourArrayValue.length == 0)
        errors.q7 = "Please choose at least one response."; */

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitQuestionnaire() {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      const req = [];
      req.push(this.q1);
      req.push(this.q3);

      this.inputValueAvoid.map((item) =>
        req.push({
          id: 4,
          custom_answer: null,
          questionnaire: 4,
          choice: Number(item),
        })
      );

      this.inputValueBehaviour.map((item) =>
        req.push({
          id: 7,
          custom_answer: null,
          questionnaire: 7,
          choice: Number(item),
        })
      );

      console.log(req);
      await intakeQuestionnaire(this.petData.petID, req).then(() => {
        this.$router.push({ path: "/onboardingdashboard" });
      });
    },
  },
};
</script>

<style scoped>
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border: 2px solid #0ac0cb;
  background-color: #eee;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
  top: -1px;
  left: 3px;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>
