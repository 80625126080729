<template>
  <div class="relative">
    <HeaderGlobal />
    <img
      class="h-32 top-0 left-5 hidden md:block absolute"
      src="@/assets/images/dataservice-logo.svg"
      alt="dataservice-logo"
    />
    <div>
      <div class="pt-8 font-proximaLight">
        <h1 class="text-4xl font-medium">Intake Questionnaire</h1>
        <h3 class="text-xl mt-2">
          Complete this questionnaire to optimize your pet service visits!
        </h3>
        <FormError :errors="errorForm" />
      </div>
      <QuestionnaireForms />
    </div>
  </div>
</template>

<script>
import QuestionnaireForms from "./QuestionnaireForms.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import FormError from "../warning/FormError.vue";

export default {
  components: {
    QuestionnaireForms,
    HeaderGlobal,
    FormError,
  },
  data() {
    return {};
  },
  computed: {
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
};
</script>

<style></style>