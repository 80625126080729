<template>
  <div class="my-6">
    <QuestionnaireSection />
  </div>
</template>

<script>
import QuestionnaireSection from "../components/intakequestionnaire/QuestionnaireSection.vue";

export default {
  components: {
    QuestionnaireSection
  },
  created() {
    
  },
};
</script>